import http from "../http-common";
import authHeader from '../auth-header';

class ConfigurazioneLiturgiaDataService {
    //getAll() {
    //    return http.get("/tutorials");
    //}
   
    async get(id: string) {
        return await http.get(`/configurazioneliturgia/${id}`, { headers: authHeader() });
    }

    async getAll() {
        return await http.get(`/configurazioneliturgia`, { headers: authHeader() });
    }

    async search(data: any) {
        return await http.post(`/configurazioneliturgia/SearchConfigurazioniLiturgia`, data, { headers: authHeader("application/json") });
    }

    async create(data: any) {
        return await http.post("/configurazioneliturgia/CreaConfigurazioneLiturgia", data, { headers: authHeader() });
    }

    async update(data: any) {
        return await http.put(`/configurazioneliturgia/${data.id}`, data, { headers: authHeader() });
    }

    async delete(id: string) {
        return await http.delete(`/configurazioneliturgia/${id}`, { headers: authHeader() });
    }

    //deleteAll() {
    //    return http.delete(`/tutorials`);
    //}

    //findByTitle(title: string) {
    //    return http.get(`/tutorials?title=${title}`);
    //}
}

export default new ConfigurazioneLiturgiaDataService();