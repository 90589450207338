

































































































































































import { Component, Vue } from 'vue-property-decorator';
import 'vue-good-table/dist/vue-good-table.css';
import ConfigurazioneLiturgiaDataService from "../services/ConfigurazioneLiturgiaDataService";
import ConfigurazioneLiturgiaRow from '../model/ConfigurazioneLiturgiaRow';
import ConfigurazioneLiturgiaEntryPoint from "../EntryPoint/ConfigurazioneLiturgiaEntryPoint";

import { namespace } from "vuex-class";
import { Utils } from '../utils/utils';
const Auth = namespace("Auth");

@Component({
    components: {
        'vue-good-table': require('vue-good-table').VueGoodTable,
    }
})
export default class RicercaConfigurazioneLiturgia extends Vue {


    @Auth.State("user")
    private currentUser!: any;

    @Auth.State("nameidentifier")
    private userId!: any;

    @Auth.State("status")
    private status!: any;

    @Auth.Action
    public signOut!: () => void;

    get IsLogged() {
        return this.status.loggedIn;
    }

    isOwner(userId: any) {
        return this.IsLogged && this.userId == userId;
    }


    // Initial data can be declared as instance properties
    private pageTitle: string = 'Ricerca Configurazione Liturgia';

    private sortBy: string = "dataLiturgia";
    private sortDesc: boolean = true;
    private sortDirection: any = "desc";

    private totalRows: number = 1;
    private currentPage: number = 1;
    private perPage: number = 10;
    private pageOptions: any = [5, 10, 15, { value: 100, text: "Show a lot" }];

    private filter: any = null;
    private filterOn: any = ["nome", "dataLiturgia"];

    private filters: any = {
        nome: '',
        dataLiturgia: ''
    };

    get dateNow() {
        var today = new Date();
        return today;
    }

    canDettaglioConfigurazione(configurazioneLiturgiaRow: ConfigurazioneLiturgiaRow) {
        return !this.canModificaConfigurazione(configurazioneLiturgiaRow);
    }

    canModificaConfigurazione(configurazioneLiturgiaRow: ConfigurazioneLiturgiaRow) {
        return this.isOwner(configurazioneLiturgiaRow.idUtenteCreazione) && (configurazioneLiturgiaRow.dataLiturgia == null || new Date((new Date(configurazioneLiturgiaRow.dataLiturgia)).valueOf() + 1000 * 3600 * 24) > this.dateNow);
    }

    canDuplicaConfigurazione(configurazioneLiturgiaRow: ConfigurazioneLiturgiaRow) {
        return this.isOwner(configurazioneLiturgiaRow.idUtenteCreazione);
    }

    canDeleteConfigurazione(configurazioneLiturgiaRow: ConfigurazioneLiturgiaRow) {
        return this.isOwner(configurazioneLiturgiaRow.idUtenteCreazione) && (configurazioneLiturgiaRow.dataLiturgia == null || new Date((new Date(configurazioneLiturgiaRow.dataLiturgia)).valueOf() + 1000 * 3600 * 24) > this.dateNow);
    }

    canShowElencoCanzoniCOnAccordiConfigurazione(configurazioneLiturgiaRow: ConfigurazioneLiturgiaRow) {
        return configurazioneLiturgiaRow.configuratoreLiturgiaCanzoneRows.length > 0;
    }

    //private Titolo: string[] = ["Boulevard", "Holiday", "Testa tra le nuvole", "Testa tra le nuvole parte 2", "Chiasso"];
    //private Autore: string[] = ["Green Day", "Green Day", "Alfa", "Alfa", "Random"];
    //private Tipologia: string[] = ["Rock", "Acoustic", "Acoustic", "Acoustic", "Rap"];

    private modalShow: boolean = false;
    private feedback: string = "";
    private feedbackshow: boolean = false;
    private feedbackAlertType: string = "";

    private Columns: Array<any> = [
        {
            label: "Nome",
            key: "nome",
            sortable: true
        },
        {
            label: "Data Liturgia",
            key: "dataLiturgia",
            sortable: true,
            formatter: (value: any, key: any, item: any) => {
                return Utils.convertDate(value);
            },
            filterByFormatted: true
        },
        {
            label: "Elenco Canzoni",
            key: "elencocanzoni"
            //sortable: true,
            //formatter: (value: any, key: any, item: any) => {
            //    if (value == null)
            //        return "";
            //    //console.log(value);
            //    //console.log(item);
            //    var canzoniSelectedListGroupByParteDellaMessa = Utils.groupBy(value, 'parteDellaMessaNome');
            //    console.log(canzoniSelectedListGroupByParteDellaMessa);
            //    console.log(Object.entries(canzoniSelectedListGroupByParteDellaMessa));

            ////     <h1 style="text-align: center;">{{key}}</h1>
            ////<div class="mt-3" v-for="configurazione in configurazioniPerParteDellaMessa">
            ////    <FormCanzone :canzone="configurazione.canzone" v-bind:readonly="true" v-bind:editoronly="true"></FormCanzone>
            ////</div>
            //    var result: string = "";
            //    Object.entries(canzoniSelectedListGroupByParteDellaMessa).forEach(function (configurazioniPerParteDellaMessa: any) {
            //        console.log("configurazioniPerParteDellaMessa-->");
            //        console.log(configurazioniPerParteDellaMessa[1]);
            //        result += " <b style='text - align: center;'>" + configurazioniPerParteDellaMessa[0] + "</b>";
            //        result += "<ul>";
            //        configurazioniPerParteDellaMessa[1].forEach(function (ConfiguratoreLiturgiaCanzoneRow: any) {
            //            result += "<li>" + ConfiguratoreLiturgiaCanzoneRow.canzone.titolo + "</li>";
            //        });
            //        result += "</ul>";
            ////    <FormCanzone :canzone="configurazione.canzone" v-bind:readonly="true" v-bind:editoronly="true"></FormCanzone>
            ////</div>"
            //    });
            //    return result;
            //},
            //filterByFormatted: true
        },
        //{
        //    label: "Tempi Liturgici",
        //    key: "tempiLiturgici",
        //    sortable: true
        //},
        //{
        //    label: "Parole chiave",
        //    key: "paroleChiave",
        //    sortable: true
        //},
        {
            label: "Visibilita'",
            key: "visibilityPolicy",
            sortable: true,
            formatter: (value: any) => {
                return value == 1 ? "Privata" : "Pubblica";
            }
            //filterByFormatted: true
        },
        {
            key: 'azioni',
            label: 'Azioni',
            sortable: false
        },
    ];


    //private items: Array<any> = [
    //    { titolo: "Gli anni", id: 1, autore: "883", partiDellaMessa: "Ingresso", tempiLiturgici: "Ordinario", paroleChiave: "Perdono, Speranza", urlModifica: "modificacanzone/1" }]




    private Rows: ConfigurazioneLiturgiaRow[] = [];

    getConfigurazioniLiturgia() {
        console.log("getConfigurazioniLiturgia-->");
        ConfigurazioneLiturgiaEntryPoint.getAll().then((data: any) => {
            //console.log("data-->" + data);
            this.Rows = data;
            this.totalRows = data.length;
            this.Rows.forEach(function (row: any) {
                //console.log("row-->");
                //console.log(row);
                row.canzoniSelectedListGroupByParteDellaMessa = Utils.groupBy(
                    row.configuratoreLiturgiaCanzoneRows.sort(function (a:any, b:any) {
                    return a.parteDellaMessaOrdinamento - b.parteDellaMessaOrdinamento;
                    }),
                    'parteDellaMessaNome');
            });
            //console.log("this.Rows-->");
            //console.log(this.Rows);
        })
            .catch((e: any) => {
                this.feedbackshow = true;
                this.feedbackAlertType = "danger";
                this.feedback = e.message;
                console.log(e);
            });
    }

    private idToDelete: string = '';
    showModalDelete(id: string) {
        this.idToDelete = id;
        (this.$refs['modaleDelete'] as any).show()
        //this.$bvModal.show('modaleDelete');
    };
    hideModal() {
        (this.$refs['modaleDelete'] as any).hide()
        //this.$bvModal.hide('modaleDelete');
    };

    deleteRow() {
        ConfigurazioneLiturgiaDataService.delete(this.idToDelete)
            .then((response) => {
                //this.canzone.id = response.data.id;
                //this.feedbackItem = new FeedbackItem(true, true);
                this.feedbackshow = true;
                this.feedbackAlertType = "success";
                this.feedback = "Configurazione liturgia eliminata con successo";
                this.getConfigurazioniLiturgia();
                //this.cleancCanzoneFormGroup();
                console.log(response.data);
            })
            .catch((e) => {
                this.feedbackshow = true;
                this.feedbackAlertType = "danger";
                this.feedback = e.message;
                console.log(e);

                if (e.response.status === 401) {
                    this.signOut();
                    this.$router.push("/login");
                }
            });

        this.hideModal();
    }

    onFiltered(filteredItems: any) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
    }

    filtered() {
        const filtered = this.Rows;
        //const filtered = this.Rows.filter(row => {
        //    return Object.keys(this.filters).every(key =>
        //        String(row[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
        //})
        //console.log(filtered);
        return filtered.length > 0 ? filtered : [{
            nome: '',
            dataLiturgia: '',
        }]
    }

    mounted() {
        this.getConfigurazioniLiturgia();        
        // Set the initial number of items
    }

}

