import CanzoneModel from './CanzoneModel';
import MultipleMultiselectItem from './MultipleMultiselectItem';

export default class ConfiguratoreLiturgiaCanzoneRow {
    canzone: CanzoneModel;
    parteDellaMessa: MultipleMultiselectItem;
    parteDellaMessaNome: string;
    parteDellaMessaOrdinamento: number;
    numeroParoleTrovate: number = 0;
    paroleTrovate: string[] = [];
    selected: boolean = false;
    constructor(_canzone: CanzoneModel, _parteDellaMessa: MultipleMultiselectItem) {
        this.canzone = _canzone;
        this.parteDellaMessa = _parteDellaMessa;
        this.parteDellaMessaNome = _parteDellaMessa.nome;
        this.parteDellaMessaOrdinamento = _parteDellaMessa.ordinamento;
    }    
}