import ConfigurazioneLiturgiaDataService from "../services/ConfigurazioneLiturgiaDataService";
import ConfigurazioneLiturgiaSearchCriteria from '../model/ConfigurazioneLiturgiaSearchCriteria';
import ConfigurazioneLiturgiaRow from '../model/ConfigurazioneLiturgiaRow';
import ConfiguratoreLiturgiaCanzoneRow from "../model/ConfiguratoreLiturgiaCanzoneRow";
import CanzoneModel from "../model/CanzoneModel";
import MultipleMultiselectItem from "../model/MultipleMultiselectItem";
import { Utils } from "../utils/utils";
class ConfigurazioneLiturgiaEntryPoint {

    search(criteria: ConfigurazioneLiturgiaSearchCriteria): any {
        var configurazioneLiturgiaRows: ConfigurazioneLiturgiaRow[] = [];
        return ConfigurazioneLiturgiaDataService.search(criteria)
            .then((response) => {
                //console.log(response);
                response.data.forEach(function (configurazioneLiturgia: any) {
                    var configuratoreLiturgiaCanzoneRows: ConfiguratoreLiturgiaCanzoneRow[] = [];
                    configurazioneLiturgia.configurazioneLiturgiaCanzoni.forEach(function (configurazioneLiturgiaCanzone: any) {
                        if (configurazioneLiturgiaCanzone != null) {
                            var configuratoreLiturgiaCanzoneRow = new ConfiguratoreLiturgiaCanzoneRow(new CanzoneModel(configurazioneLiturgiaCanzone.canzone.titolo, configurazioneLiturgiaCanzone.canzone.id, configurazioneLiturgiaCanzone.canzone.idUtenteCreazione, configurazioneLiturgiaCanzone.canzone.autore, configurazioneLiturgiaCanzone.canzone.testo, configurazioneLiturgiaCanzone.partiDellaMessa, configurazioneLiturgiaCanzone.tempLiturgici, configurazioneLiturgiaCanzone.paroleChiave, configurazioneLiturgiaCanzone.canzone.visibilityPolicy, configurazioneLiturgiaCanzone.canzone.allegati), new MultipleMultiselectItem(configurazioneLiturgiaCanzone.parteDellaMessa.nome, configurazioneLiturgiaCanzone.parteDellaMessa.id, configurazioneLiturgiaCanzone.parteDellaMessa.ordinamento));
                            configuratoreLiturgiaCanzoneRow.numeroParoleTrovate = configurazioneLiturgiaCanzone.paroleTrovate.length;
                            configuratoreLiturgiaCanzoneRow.paroleTrovate = configurazioneLiturgiaCanzone.paroleTrovate;
                            configuratoreLiturgiaCanzoneRow.selected = true;
                            configuratoreLiturgiaCanzoneRows.push(configuratoreLiturgiaCanzoneRow);
                        }
                    });                   
                    configurazioneLiturgiaRows.push(new ConfigurazioneLiturgiaRow(configuratoreLiturgiaCanzoneRows, configurazioneLiturgia.id, configurazioneLiturgia.nome, configurazioneLiturgia.idUtenteCreazione, configurazioneLiturgia.dataLiturgia, configurazioneLiturgia.visibilityPolicy, Utils.convertDate(configurazioneLiturgia.dataCreazione)));
                    
                });
                return configurazioneLiturgiaRows;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getAll(): any {
        var configurazioneLiturgiaRows: ConfigurazioneLiturgiaRow[] = [];
        return ConfigurazioneLiturgiaDataService.getAll()
            .then((response) => {
                //console.log(response);                
                response.data.forEach(function (configurazioneLiturgia: any) {
                    var configuratoreLiturgiaCanzoneRows: ConfiguratoreLiturgiaCanzoneRow[] = [];
                    configurazioneLiturgia.configurazioneLiturgiaCanzoni.forEach(function (configurazioneLiturgiaCanzone: any) {
                        //console.log(configurazioneLiturgiaCanzone);
                        if (configurazioneLiturgiaCanzone != null) {
                            var configuratoreLiturgiaCanzoneRow = new ConfiguratoreLiturgiaCanzoneRow(new CanzoneModel(configurazioneLiturgiaCanzone.canzone.titolo, configurazioneLiturgiaCanzone.canzone.id, configurazioneLiturgiaCanzone.canzone.idUtenteCreazione, configurazioneLiturgiaCanzone.canzone.autore, configurazioneLiturgiaCanzone.canzone.testo, configurazioneLiturgiaCanzone.partiDellaMessa, configurazioneLiturgiaCanzone.tempLiturgici, configurazioneLiturgiaCanzone.paroleChiave, configurazioneLiturgiaCanzone.canzone.visibilityPolicy, configurazioneLiturgiaCanzone.canzone.allegati), new MultipleMultiselectItem(configurazioneLiturgiaCanzone.parteDellaMessa.nome, configurazioneLiturgiaCanzone.parteDellaMessa.id, configurazioneLiturgiaCanzone.parteDellaMessa.ordinamento));
                            configuratoreLiturgiaCanzoneRow.numeroParoleTrovate = configurazioneLiturgiaCanzone.paroleTrovate.length;
                            configuratoreLiturgiaCanzoneRow.paroleTrovate = configurazioneLiturgiaCanzone.paroleTrovate;
                            configuratoreLiturgiaCanzoneRow.selected = true;
                            configuratoreLiturgiaCanzoneRows.push(configuratoreLiturgiaCanzoneRow);
                        }
                    });
                    configurazioneLiturgiaRows.push(new ConfigurazioneLiturgiaRow(configuratoreLiturgiaCanzoneRows, configurazioneLiturgia.id, configurazioneLiturgia.nome, configurazioneLiturgia.idUtenteCreazione, configurazioneLiturgia.dataLiturgia, configurazioneLiturgia.visibilityPolicy, Utils.convertDate(configurazioneLiturgia.dataCreazione)));

                });
                return configurazioneLiturgiaRows;
                //console.log(this.Rows);
            })
            .catch((e) => {
                console.log(e);
            });
    }
}

export default new ConfigurazioneLiturgiaEntryPoint();