import ConfiguratoreLiturgiaCanzoneRow from './ConfiguratoreLiturgiaCanzoneRow';

export default class ConfigurazioneLiturgiaRow {
    configuratoreLiturgiaCanzoneRows: ConfiguratoreLiturgiaCanzoneRow[];
    nome: string;
    idUtenteCreazione: any;
    dataLiturgia: Date;
    dataCreazione: string;
    visibilityPolicy: any;
    urlModifica: string;
    urlDuplica: string;
    urlElencoCanzoni: string;
    id: string;    
    //[key: string]: string;
    constructor(_configuratoreLiturgiaRow: ConfiguratoreLiturgiaCanzoneRow[], _id: string, _nome: string, _idUtenteCreazione: any, _dataLiturgia: Date, _visibilityPolicy: number, _dataCreazione: string) {
        this.configuratoreLiturgiaCanzoneRows = _configuratoreLiturgiaRow;
        this.id = _id;
        this.nome = _nome;
        this.idUtenteCreazione = _idUtenteCreazione;
        this.dataLiturgia = _dataLiturgia;
        this.dataCreazione = _dataCreazione;
        this.visibilityPolicy = _visibilityPolicy;
        this.urlModifica = "/configuratoreliturgia/" + _id;
        this.urlDuplica = "/configuratoreliturgia?duplica=true&idDuplica=" + _id.toString();
        this.urlElencoCanzoni = "/elencocanzoniconfigurazioneliturgica/" + _id;
    }    
}